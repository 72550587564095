import React, { useState, useMemo } from 'react';
import { CellProps, Column, TableState } from 'react-table';
import { css } from '@emotion/css';
import { Badge, Button, Field, Input, LoadingPlaceholder, MultiSelect, Select, useStyles } from '@grafana/ui';
import { AppPluginMeta, DataSourceSettings, GrafanaTheme, KeyValue, SelectableValue } from '@grafana/data';
import {
  AthenaConfig,
  RedshiftConfig,
  AWSAccount,
  AWSServiceType,
  InstallableData,
  ProvisioningAppSettings,
  Region,
  Resource,
  AWSDataSourceJsonData,
} from 'types/types';
import { TabComponentProps } from '../Navigation/Tabs';
import { ResourceTable } from '../ResourceTable';
import { getDataType } from '../../utils';
import { useData, useResources } from '../../hooks';
import { ASSETS_ROOT } from '../../constants';
import { DataSourceTable } from 'components/DataSourceTable';
import {
  createCloudWatchDataSource,
  createOpenSearchDataSource,
  createPrometheusDataSource,
  createSiteWiseDataSource,
  createTimeStreamDataSource,
  createXRayDataSource,
  createAthenaDataSource,
  createRedshiftDataSource,
  createTwinMakerDataSource,
} from '../../provisioning';
import { getBackendSrv, config, getDataSourceSrv } from '@grafana/runtime';
import { MigrationBox } from '../MigrationBox';
import { getServiceIcon } from '../../utils/icons';
import { ESVersionCell } from './ESVersionCell';
import { ESVersion, extractESVersion } from 'provisioning/opensearch';
import { WarningBox } from '../WarningBox';
import { TwinMakerBox } from 'components/TwinMakerBox/TwinMakerBox';
import { uniq } from 'lodash';

export interface Props extends TabComponentProps {
  query: KeyValue;
  meta: AppPluginMeta<ProvisioningAppSettings>;
}

const updateUrlQueryParams = (
  service?: string,
  defaultRegion?: string,
  regionIds?: string[],
  accountIds?: string[]
) => {
  let url = `${window.location.pathname}?tab=datasources`;
  if (service) {
    url += `&id=${service}`;
  }

  if (defaultRegion) {
    url += `&defaultRegion=${defaultRegion}`;
  }

  if (regionIds && regionIds.length > 0) {
    url += `&regions=${regionIds.join(',')}`;
  }

  if (accountIds && accountIds.length > 0) {
    url += `&accounts=${accountIds.join(',')}`;
  }

  window.history.replaceState('', '', url);
};

export const DataSources = ({ services = [], query, meta }: Props) => {
  const styles = useStyles(getStyles);
  const serviceIcon = useStyles(getServiceIcon);

  const [selectedService, setSelectedService] = useState<AWSServiceType>(query.id || services[0].id);
  const [selectedDefaultRegion, setSelectedDefaultRegion] = useState(query.defaultRegion);

  const [tableState, setTableState] = useState<TableState | null>(null);
  const service = useMemo(
    () => services.find((s) => s.id === selectedService) || services[0],
    [selectedService, services]
  );

  const {
    loading: dataLoading,
    warnings: dataWarnings,
    regions,
    accounts,
    appConfig,
    installedDataSources,
    fetchDataSources,
  } = useData(service);

  const [installedDSNames, installedDSRegions, installedDSAccountIds] = useMemo(() => {
    const dsNames = new Set<string>();
    const dsRegions = new Set<string>();
    const dsAccounts = new Set<string>();
    (installedDataSources || []).forEach((ds: DataSourceSettings<AWSDataSourceJsonData>) => {
      dsNames.add(ds.name);
      if (ds.jsonData.defaultRegion) {
        dsRegions.add(ds.jsonData.defaultRegion);
      }
      if (ds.jsonData.accountId) {
        dsAccounts.add(ds.jsonData.accountId);
      }
    });

    return [dsNames, dsRegions, dsAccounts];
  }, [installedDataSources]);

  const [selectedRegions, setSelectedRegions] = useState<Array<Region['id']>>(
    query.regions ? query.regions.split(',') : []
  );
  const [selectedAccounts, setSelectedAccounts] = useState<Array<AWSAccount['id']>>(
    query.accounts ? (query.accounts.split ? query.accounts.split(',') : [query.accounts]) : []
  );

  // Fetch resources for potential future datasources to create
  const {
    loading: resourcesLoading,
    warnings: resourcesWarnings,
    resources,
  } = useResources(appConfig ? appConfig.singleAccount : false, service.id, selectedRegions, selectedAccounts);

  // Fetch resources for already installed datasources
  const { resources: installedResources } = useResources(
    appConfig ? appConfig.singleAccount : false,
    service.id,
    uniq([...installedDSRegions]),
    uniq([...installedDSAccountIds])
  );

  // check if any installed datasources should be marked as monitoring accounts
  // (only applies to cloudwatch right now)
  // then sort the monitoring accounts at the top
  const installedDatasourcesWithMonitoringFlags = useMemo(() => {
    const dsWithMonitoringInfo = (installedDataSources || []).map((ds: DataSourceSettings<AWSDataSourceJsonData>) => {
      let r: Resource;
      for (r of installedResources || []) {
        const sameAccount = r.accountId && ds.jsonData.accountId ? r.accountId === ds.jsonData.accountId : true;
        if (sameAccount && r.regionId === ds.jsonData.defaultRegion) {
          ds.jsonData.isMonitoring = r.cloudwatchInfo.isMonitoring;
          break;
        }
      }
      return ds;
    });
    const monitoringDs = dsWithMonitoringInfo
      .filter((ds) => ds.jsonData.isMonitoring)
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    const nonMonitoringDs = dsWithMonitoringInfo
      .filter((ds) => !ds.jsonData.isMonitoring)
      .sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    return [...monitoringDs, ...nonMonitoringDs];
  }, [installedDataSources, installedResources]);

  const hasResources = !service?.isFlat;
  const warnings = [...(dataWarnings || []), ...(resourcesWarnings || [])];

  // Options for selectors
  const enabledServices = useMemo(() => (services || []).filter((service) => service.enabled), [services]);
  const regionOptions = useMemo(() => (regions || []).map((r: Region) => ({ label: r.name, value: r.id })), [regions]);
  const serviceOptions: Array<SelectableValue<AWSServiceType>> = useMemo(
    () =>
      enabledServices.map((s) => ({
        label: s.name,
        value: s.id,
        imgUrl: `${ASSETS_ROOT}/${serviceIcon(s.id)}`,
      })),
    [enabledServices, serviceIcon]
  );
  const accountOptions = useMemo(
    () =>
      (accounts || []).map((a: AWSAccount) => ({
        label: a.name,
        value: a.id,
      })),
    [accounts]
  );

  const dataType = useMemo(() => getDataType(service), [service]);

  // Define table columns
  const columns = useMemo(() => {
    const columns: Column[] = [];
    if (appConfig && !appConfig.singleAccount) {
      const accountIdAccessor = hasResources ? 'accountId' : 'id';
      const accountNameAccessor = hasResources ? 'accountName' : 'name';

      columns.push(
        { Header: 'Account id', accessor: accountIdAccessor, width: '20%' },
        { Header: 'Account name', accessor: accountNameAccessor, width: '40%' }
      );
    }
    if (!hasResources) {
      // If doesn't have resources, return already
      return columns;
    }

    // Region is always present
    columns.push({ Header: 'Region', accessor: 'regionId', width: '10%' });
    // Case: Athena
    if (selectedService === AWSServiceType.Athena) {
      columns.push(
        { Header: 'Data source', accessor: 'athenaInfo.catalog', width: '30%' },
        { Header: 'Database', accessor: 'athenaInfo.database', width: '30%' },
        { Header: 'Workgroup', accessor: 'athenaInfo.workgroup', width: '30%' }
      );
      return columns;
    }
    // Case: Redshift
    if (selectedService === AWSServiceType.Redshift) {
      columns.push(
        { Header: 'Cluster or Workgroup', accessor: 'resourceId', width: '30%' },
        { Header: 'Database', accessor: 'redshiftInfo.database', width: '25%' },
        { Header: 'Database User', accessor: 'redshiftInfo.dbUser', width: '25%' },
        {
          Header: 'Type',
          accessor: 'redshiftInfo.serverless',
          Cell: ({ value }: { value: boolean }) =>
            !!value ? (
              <Badge text="Serverless" color="blue" tooltip="Serverless Workgroup"></Badge>
            ) : (
              <Badge text="Provisioned" color="red" tooltip="Provisioned Cluster"></Badge>
            ),
          width: '10%',
        }
      );
      return columns;
    }
    // Case: TwinMaker
    if (selectedService === AWSServiceType.TwinMaker) {
      columns.push(
        { Header: 'Workspace', accessor: 'twinMakerInfo.workspaceId', width: '20%' },
        { Header: 'Description', accessor: 'twinMakerInfo.workspaceDescription', width: '20%' },
        {
          Header: 'Dashboard Role',
          accessor: 'twinMakerInfo.dashboardRole',
          width: '60%',
          Cell: ({ value }) => value || '-',
        }
      );
      return columns;
    }
    // Case: OpenSearch
    if (selectedService === AWSServiceType.OpenSearch) {
      columns.push(
        { Header: 'Resource id', accessor: 'resourceId', width: '25%' },
        {
          Header: 'ES Version',
          id: 'esVersion',
          Cell: ESVersionCell,
          width: '200px',
        },
        {
          Header: 'Index',
          id: 'index',
          Cell: ({ row, setCellState }: CellProps<any>) => {
            return (
              <Input
                placeholder="Index"
                onChange={({ target }) => setCellState([row.id], 'index', (target as HTMLInputElement).value)}
              />
            );
          },
        }
      );
      return columns;
    }

    if (selectedService === AWSServiceType.CloudWatch) {
      columns.push({
        accessor: 'cloudwatchInfo.isMonitoring',
        Cell: ({ value }: { value: boolean }) =>
          !!value ? (
            <Badge
              text="Monitoring account"
              color="blue"
              tooltip="AWS monitoring accounts view data from source accounts so you can centralize monitoring and troubleshoot activities"
            ></Badge>
          ) : (
            ''
          ),
      });
      return columns;
    }
    // Case: default
    columns.push(
      { Header: 'Resource id', accessor: 'resourceId', width: '35%' },
      { Header: 'Resource alias', accessor: 'name' }
    );
    return columns;
  }, [appConfig, selectedService, hasResources]);

  // Table data builder for resources
  const buildResourcesData = (accounts?: AWSAccount[], resources?: Resource[]) => {
    if (!resources || !accounts) {
      return [];
    }

    const accountsById: { [key: string]: AWSAccount } = accounts.reduce((acc: object, account: AWSAccount) => {
      return { ...acc, [account.id]: account };
    }, {});

    const mappedResources = resources.map((r) => ({
      accountId: r.accountId,
      accountName: accountsById[r.accountId]?.name,
      resourceId: r.id,
      regionId: r.regionId,
      endpoint: r.endpoint,
      name: r.name,
      athenaInfo: r.athenaInfo,
      twinMakerInfo: r.twinMakerInfo,
      redshiftInfo: r.redshiftInfo,
      openSearchInfo: {
        serverless: r.openSearchInfo?.serverless,
        esVersion: extractESVersion(r.openSearchInfo?.esVersion),
      },
      cloudwatchInfo: r.cloudwatchInfo,
    }));

    if (mappedResources.length === 0 || !mappedResources[0].cloudwatchInfo) {
      return mappedResources;
    }

    const monitoringResources = mappedResources
      .filter((r) => r.cloudwatchInfo?.isMonitoring)
      .sort((a, b) => {
        if (a.accountName && b.accountName) {
          return a.accountName.localeCompare(b.accountName);
        }
        return a.regionId.localeCompare(b.regionId);
      });

    const nonMonitoringResources = mappedResources
      .filter((r) => !r.cloudwatchInfo?.isMonitoring)
      .sort((a, b) => {
        if (a.accountName && b.accountName) {
          return a.accountName.localeCompare(b.accountName);
        }
        return a.regionId.localeCompare(b.regionId);
      });

    return [...monitoringResources, ...nonMonitoringResources];
  };

  // Build table data
  const data = useMemo(
    () => (hasResources ? buildResourcesData(accounts, resources) : accounts || []),
    [hasResources, accounts, resources]
  );

  const updateFrontendSettings = () => {
    return getBackendSrv()
      .get('/api/frontend/settings')
      .then((settings: any) => {
        config.datasources = settings.datasources;
        config.defaultDatasource = settings.defaultDatasource;
        (getDataSourceSrv as any)().init(config.datasources, settings.defaultDatasource);
      });
  };

  const selectedRows = tableState ? Object.keys(tableState.selectedRowIds) : [];

  const installSelected = async () => {
    const roleName = appConfig.orgRoleName;
    const workspaceAccountId = appConfig.workspaceAccountId;

    if (tableState) {
      const selected = Object.keys(tableState.selectedRowIds);
      if (selected.length) {
        let processedData: InstallableData[];

        if (!hasResources) {
          processedData = selected.map((key) => ({
            account: accounts.find((a: AWSAccount) => a.id === data[Number(key)].id),
            region: selectedDefaultRegion,
            service: selectedService,
          }));
        } else {
          processedData = selected.map((key) => {
            return {
              resourceId: data[Number(key)].resourceId,
              endpoint: data[Number(key)].endpoint,
              region: data[Number(key)].regionId,
              service: selectedService,
              account: accounts.find((a: AWSAccount) => a.id === data[Number(key)].accountId),
              athenaInfo: data[Number(key)].athenaInfo,
              redshiftInfo: data[Number(key)].redshiftInfo,
              twinMakerInfo: data[Number(key)].twinMakerInfo,
              openSearchInfo: {
                serverless: data[Number(key)].openSearchInfo?.serverless,
                esVersion: (tableState.rowState[Number(key)]?.cellState.esVersion ||
                  data[Number(key)]?.openSearchInfo?.esVersion) as ESVersion | undefined,
              },
              cloudwatchInfo: data[Number(key)].cloudwatchInfo,
              ...(selectedService === AWSServiceType.OpenSearch && {
                index: tableState.rowState[Number(key)]?.cellState.index as string,
              }),
            };
          });
        }

        for (const selectedItem of processedData) {
          switch (selectedItem.service) {
            case AWSServiceType.CloudWatch:
              const cloudwatchConfig = meta.jsonData?.serviceConfig.cloudwatch;
              await createCloudWatchDataSource(
                {
                  account: selectedItem.account,
                  roleName,
                  region: selectedItem.region,
                  config: cloudwatchConfig,
                },
                installedDSNames,
                workspaceAccountId
              );
              break;
            case AWSServiceType.SiteWise:
              if (selectedItem.account && selectedItem.region) {
                await createSiteWiseDataSource(
                  {
                    account: selectedItem.account,
                    roleName,
                    region: selectedItem.region,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.TimeStream:
              if (selectedItem.account && selectedItem.region) {
                await createTimeStreamDataSource(
                  {
                    account: selectedItem.account,
                    roleName,
                    region: selectedItem.region,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.XRay:
              if (selectedItem.account && selectedItem.region) {
                await createXRayDataSource(
                  {
                    account: selectedItem.account,
                    roleName,
                    region: selectedItem.region,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.Prometheus:
              if (selectedItem.resourceId && selectedItem.endpoint) {
                const prometheusConfig = meta.jsonData?.serviceConfig.prometheus;
                await createPrometheusDataSource(
                  {
                    roleName,
                    id: selectedItem.resourceId,
                    account: selectedItem.account,
                    region: selectedItem.region,
                    endpoint: selectedItem.endpoint,
                    config: prometheusConfig,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.OpenSearch:
              if (selectedItem.resourceId && selectedItem.endpoint) {
                await createOpenSearchDataSource(
                  {
                    roleName,
                    id: selectedItem.resourceId,
                    account: selectedItem.account,
                    region: selectedItem.region,
                    endpoint: selectedItem.endpoint,
                    database: selectedItem.index,
                    serverless: selectedItem.openSearchInfo?.serverless,
                    version: selectedItem.openSearchInfo?.esVersion,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.Athena:
              if (selectedItem.region) {
                const athenaConfig: AthenaConfig = selectedItem.athenaInfo || {};
                await createAthenaDataSource(
                  {
                    account: selectedItem.account,
                    roleName,
                    region: selectedItem.region,
                    config: athenaConfig,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.Redshift:
              if (selectedItem.region) {
                const redshiftConfig: RedshiftConfig = selectedItem.redshiftInfo || {};
                await createRedshiftDataSource(
                  {
                    useServerless: selectedItem.redshiftInfo?.serverless || false,
                    workgroupName: selectedItem.resourceId || '',
                    clusterIdentifier: selectedItem.resourceId || '',
                    account: selectedItem.account,
                    roleName,
                    region: selectedItem.region,
                    config: redshiftConfig,
                  },
                  installedDSNames,
                  workspaceAccountId
                );
              }
              break;
            case AWSServiceType.TwinMaker:
              if (selectedItem.region && selectedItem.twinMakerInfo) {
                await createTwinMakerDataSource(
                  {
                    account: selectedItem.account,
                    region: selectedItem.region,
                    workspaceId: selectedItem.twinMakerInfo.workspaceId,
                    dashboardRole: selectedItem.twinMakerInfo.dashboardRole,
                  },
                  installedDSNames
                );
              }
              break;
          }
        }
      }
      await fetchDataSources();
      await updateFrontendSettings();
    }
  };

  const installSingle = async () => {
    const workspaceAccountId = appConfig.workspaceAccountId;

    switch (selectedService) {
      case AWSServiceType.CloudWatch:
        const cloudwatchConfig = meta.jsonData?.serviceConfig.cloudwatch;
        await createCloudWatchDataSource(
          {
            region: selectedDefaultRegion,
            config: cloudwatchConfig,
          },
          installedDSNames,
          workspaceAccountId
        );
        break;
      case AWSServiceType.SiteWise:
        await createSiteWiseDataSource({ region: selectedDefaultRegion }, installedDSNames, workspaceAccountId);
        break;
      case AWSServiceType.TimeStream:
        await createTimeStreamDataSource({ region: selectedDefaultRegion }, installedDSNames, workspaceAccountId);
        break;
      case AWSServiceType.XRay:
        await createXRayDataSource({ region: selectedDefaultRegion }, installedDSNames, workspaceAccountId);
        break;
      case AWSServiceType.Athena:
        await createAthenaDataSource({ region: selectedDefaultRegion }, installedDSNames, workspaceAccountId);
        break;
    }
    await fetchDataSources();
    await updateFrontendSettings();
  };

  /**
   * Disable install button if no resources are selected or if the resource is not flat and no accounts or regions are selected
   */
  const isDisabled = () => {
    if (appConfig.singleAccount && !hasResources && selectedDefaultRegion) {
      return false;
    }

    return (
      !tableState ||
      !Object.keys(tableState.selectedRowIds).length ||
      (dataType !== 'account' && (!selectedAccounts || !selectedRegions)) ||
      (dataType === 'account' && !selectedDefaultRegion)
    );
  };

  if (dataLoading) {
    return (
      <div className={styles.loader}>
        <LoadingPlaceholder text="Fetching data..." />
      </div>
    );
  }

  return (
    <div>
      <MigrationBox />
      {selectedService === AWSServiceType.TwinMaker && <TwinMakerBox />}
      {warnings.length > 0 && <WarningBox warnings={warnings} />}
      <div className={styles.serviceRow}>
        <h3>Service</h3>
        <Select
          width={42}
          options={serviceOptions}
          onChange={({ value }) => {
            setSelectedService(value!);
            updateUrlQueryParams(value, selectedDefaultRegion, selectedRegions, selectedAccounts);
            setTableState(null);
          }}
          value={serviceOptions.find((s) => s.value === selectedService)}
        />
      </div>

      <div className={styles.createDataSourceContainer}>
        <div className={styles.browseDataSourceHeader}>
          <h4>Browse and provision data sources</h4>
          <span className={styles.browseDataSourceText}>
            Specify the required configuration parameters to add data sources.
          </span>
        </div>
        <div className={styles.actionRow}>
          <div className={styles.selectRow}>
            {hasResources ? (
              <>
                <Field label={'Regions'} className={styles.regionSelect}>
                  <MultiSelect
                    width={24}
                    onChange={(items) => {
                      const regionIds = items.map((item: SelectableValue) => item.value);
                      setSelectedRegions(regionIds);
                      updateUrlQueryParams(selectedService, selectedDefaultRegion, regionIds, selectedAccounts);
                    }}
                    options={regionOptions}
                    placeholder="Select region"
                    value={selectedRegions}
                  />
                </Field>
                {!appConfig.singleAccount && (
                  <Field label={'Accounts'} className={styles.regionSelect}>
                    <MultiSelect
                      width={24}
                      onChange={(items) => {
                        const accountIds = items.map((item: SelectableValue) => item.value);
                        setSelectedAccounts(accountIds);
                        updateUrlQueryParams(selectedService, selectedDefaultRegion, selectedRegions, accountIds);
                      }}
                      options={accountOptions}
                      placeholder="Select account"
                      value={selectedAccounts}
                    />
                  </Field>
                )}
              </>
            ) : (
              <Field label={'Default region'} className={styles.regionSelect}>
                <Select
                  width={24}
                  onChange={({ value }) => {
                    setSelectedDefaultRegion(value);
                    updateUrlQueryParams(selectedService, value, selectedRegions, selectedAccounts);
                  }}
                  options={regionOptions}
                  placeholder="Select region"
                  value={selectedDefaultRegion}
                />
              </Field>
            )}
          </div>
        </div>

        {resourcesLoading ? (
          <div className={styles.innerLoader}>
            <LoadingPlaceholder text="Fetching data..." />
          </div>
        ) : appConfig.singleAccount && !hasResources ? (
          <div>
            <Button
              onClick={async () => {
                await installSingle();
                window.location.reload();
              }}
              disabled={isDisabled()}
            >
              {'Add data source'}
            </Button>
          </div>
        ) : (
          <div>
            {data.length > 0 ? (
              <div className={styles.tableContainer}>
                <ResourceTable columns={columns} data={data} onChange={setTableState} />
              </div>
            ) : (
              <div className={styles.tableContainer}>{`No results.`}</div>
            )}
            <Button
              className={data.length > 0 ? styles.createDataSourceButtonOnResults : styles.createDataSourceButton}
              onClick={async () => {
                await installSelected();
              }}
              disabled={isDisabled()}
            >
              {getDataSourcesText(selectedRows.length)}
            </Button>
          </div>
        )}
      </div>

      {installedDataSources && installedDataSources.length > 0 && (
        <>
          <h4>Provisioned data sources</h4>
          <div className={styles.tableContainer}>
            <DataSourceTable data={installedDatasourcesWithMonitoringFlags} />
          </div>
        </>
      )}
    </div>
  );
};

const getDataSourcesText = (numDataSources: number): string => {
  switch (numDataSources) {
    case 0:
      return `Add data sources`;
    case 1:
      return `Add 1 data source`;
    default:
      return `Add ${numDataSources} data sources`;
  }
};

const getStyles = (theme: GrafanaTheme) => {
  return {
    createDataSourceContainer: css`
      margin-bottom: ${theme.spacing.xl};
    `,
    browseDataSourceHeader: css`
      width: 60%;
      margin-bottom: ${theme.spacing.md};
    `,
    browseDataSourceText: css`
      color: ${theme.colors.textWeak};
    `,
    createDataSourceButton: css`
      margin-top: 10px;
    `,
    createDataSourceButtonOnResults: css`
      margin-top: -54px;
      margin-left: 20px;
    `,
    serviceRow: css`
      display: flex;
      margin-bottom: ${theme.spacing.xl};
      align-items: center;
      & > h3 {
        margin-right: ${theme.spacing.md};
        margin-bottom: 0px;
      }
    `,
    selectRow: css`
      display: flex;

      & > div {
        margin-right: ${theme.spacing.md};
      }
    `,
    actionRow: css`
      display: flex;
      justify-content: space-between;
      padding-top: ${theme.spacing.lg};
    `,
    tableContainer: css`
      display: flex;
    `,
    serviceSelect: css`
      & > div {
        position: relative;
        margin: 0;
        label {
          position: absolute;
          bottom: 4px;
        }
      }
    `,
    regionSelect: css`
      & > div {
        position: relative;
        margin: 0;
        label {
          position: absolute;
          bottom: 4px;
        }
      }
    `,
    loader: css`
      display: flex;
      width: 100%;
      justify-content: center;
    `,
    innerLoader: css`
      margin-top: 100px;
      display: flex;
      justify-content: center;
    `,
  };
};
